module.exports = function menu() {
    var menu = document.querySelector('.menu');
    var navigation = document.querySelector('nav');
    //открывание меню при клике
    menu.addEventListener('click', () => {
        if (document.querySelector('.menu-active')) {
            document.querySelector('.navigation').style.width = '0px';
            menu.classList.toggle('menu-active');
            navigation.classList.toggle('nav-active')

        } else {
            document.querySelector('.navigation').style.width = '810px';
            menu.classList.toggle('menu-active');
            navigation.classList.toggle('nav-active')
        }
    })

    //показ хедера при скороле вверх
    var lastScroll = 0;
    window.addEventListener('scroll', function () {
        var scrollTop = window.pageYOffset;
        if (lastScroll > scrollTop) {
            document.querySelector('header').style.top = '0px'
        } else if (lastScroll < scrollTop) {
            document.querySelector('header').style.top = '-73px'
        }
        lastScroll = scrollTop;

    })
};
